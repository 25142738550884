<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      justify-between
      rounded-lg cursor-pointer w-72 mt-4 md:w-80 lg:w-96 bg-backfill h-20
      text-content  py-2 px-4
      project-timeline-component
    `"
  >
    <div class="flex flex-col justify-between">
      <p class="text-left text-primary">{{title}}</p>
      <p class="text-sm font-semibold text-left text-content">{{progress}}</p>
    </div>

    <project-timeline-date
      :date="date"
      class="self-center"
    />
  </div>
</template>

<script>
import ProjectTimelineDate from '@/components/projects/timeline/ProjectTimelineDate.vue';
import moment from 'moment';

export default {
  name: 'ProjectTimelineComponent',
  components: {
    ProjectTimelineDate,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: true,
    },
  },
  computed: {
    progress() {
      const start = moment((new Date(this.date)).toDateString());
      const current = moment((new Date()).toDateString());
      const end = moment(start).add(this.duration, 'day');
      if (current.diff(end) >= 0) {
        return 'Complete';
      }
      if (current.diff(start) >= 0 && end.diff(current) >= 0) {
        return 'In Progress';
      }
      if (this.duration === 1) {
        return `${this.duration} Day`;
      }
      return `${this.duration} Days`;
    },
  },
};
</script>
