<template>
  <interior-page-content-container
    nav-selection=""
    @back-click="backBtn"
    :show-bottom-nav="false"
    :show-back-button-top-left="true"
    :show-back-button-in-nav="true"
    :showRightControls="false"
    backButtonText="Project"
    :modal-show="showWalkthroughModal"
    :loading="loading"
  >
    <template #modal>
      <walkthrough-modal
        v-if="showWalkthroughModal"
        title="Project Timeline"
        @confirm="timelineToSettings"
        @cancel="cancelWalkthrough"
        description="
          Welcome to the Project Timeline! This is where you can choose one of our
          custom KIV timelines that fit your project the best. Once chosen, you change
          the duration of the items by clicking on them or rearrange the order by visiting
          the edit portion of the timeline feature. Now let's view the project settings
        "
        textCancel='Finish'
        textConfirm='Settings'
      />
    </template>

    <template #title>
      Timeline
    </template>

    <template #content>
      <div class="`
        flex flex-col items-center self-center h-full
        w-72 pt-4 md:w-80 lg:w-96 max-w-md
        flex-shrink-0
        pb-5
      `">
        <div v-if="!timelineExists" class="flex flex-col h-full">

          <p class="text-lg font-bold text-content">
            Welcome to KIV Project Timeline!
          </p>
          <p class="my-3 font-medium text-content">
            This is where you can pick the best
            timeline that suits your KIV Project and then keep track of all
            of the tasks you will need to do.
          </p>

          <p class="my-3 font-medium text-content">
            You can create a timeline and then edit each individual item's duration
            by clicking the item itself. You can also
            rearrange each item by editing the timeline.
          </p>

          <p class="my-3 font-medium text-content">
            To get started, pick the category
            that best suits your project.
          </p>

          <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <dropdown-component
              label=""
              @save="triggerChooseList"
              :value.sync='category'
              :items="categories"
              mode="edit"
            />
          </div>

        </div>
        <div v-else class="w-full h-full">
          <p class="w-full text-xl font-extrabold text-left text-content">To Do</p>
          <project-timeline-component
            v-for="item in items.inProgress" :key="item.title"
            @click="clickItem(item)"
            :title="item.title"
            :date="item.date"
            :duration="item.duration"
          />
          <project-timeline-component
            v-for="item in items.queued" :key="item.title"
            @click="clickItem(item)"
            :title="item.title"
            :date="item.date"
            :duration="item.duration"
          />
          <p
            v-if="items.done.length > 0"
            class="w-full mt-5 text-xl font-extrabold text-left text-content"
          >
            Complete
          </p>
          <project-timeline-component
            v-for="item in items.done" :key="item.title"
            @click="clickItem(item)"
            :title="item.title"
            :date="item.date"
            :duration="item.duration"
          />

          <div class="flex flex-col items-center justify-end flex-grow pb-4">
            <button-large
              @click="clickEdit"
              class="w-20 mt-5"
              color="primary"
            >
              Edit
            </button-large>
          </div>
        </div>

      </div>
    </template>

  </interior-page-content-container>
</template>

<script>
import moment from 'moment';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ProjectTimelineComponent from '@/components/projects/timeline/ProjectTimelineComponent.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import DropdownComponent from '@/components/shared/inputs/DropdownComponent.vue';
import WalkthroughModal from '@/components/walkthrough/WalkthroughModal.vue';
import { mapProjectFields } from '@/store/mappers';
import { PROJECT_GET, PROJECT_PUT, USER_WALKTHROUGH_DISPATCH } from '@/store/actions';
import { USER_WALKTHROUGH_STATE } from '@/store/getters';
import * as projectTimelines from '@/assets/json/projectTimeline.json';

export default {
  name: 'ProjectTimeline',
  components: {
    InteriorPageContentContainer,
    ProjectTimelineComponent,
    ButtonLarge,
    DropdownComponent,
    WalkthroughModal,
  },
  data() {
    return {
      loading: true,
      category: '--Choose--',
      categoryChoose: '--Choose--',
    };
  },
  computed: {
    ...mapProjectFields(
      { timeline: 'project.timeline' },
    ),
    categories() {
      return [
        '--Choose--',
        ...Object.keys(projectTimelines.default),
      ];
    },
    items() {
      if (!this.timeline) return [];
      const date = moment(this.timeline.start_date);
      const itemsWithDate = this.timeline.to_do.map((item) => {
        const result = {
          ...item,
          date: date.toString(),
        };
        date.add(item.duration, 'day');
        return result;
      });
      return {
        done: itemsWithDate.filter((item) => this.itemSorter(item) === 'DONE'),
        inProgress: itemsWithDate.filter((item) => this.itemSorter(item) === 'IN_PROGRESS'),
        queued: itemsWithDate.filter((item) => this.itemSorter(item) === 'QUEUED'),
      };
    },
    timelineExists() {
      return !!this.timeline && this.timeline.start_date;
    },
    showWalkthroughModal() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 10.2;
    },
  },

  created() {
    this.$store.dispatch(PROJECT_GET, this.$route.params.project_id)
      .catch(() => this.$router.push({
        name: 'SpecificPageNotFound',
        params: { page: 'project' },
      }))
      .finally(() => { this.loading = false; });
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters[USER_WALKTHROUGH_STATE] < 100) {
      if (to.name === 'Project') {
        this.incrementWalkthrough(11);
      } else if (this.$store.getters[USER_WALKTHROUGH_STATE] !== 0) {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      }
    }
    next();
  },
  methods: {
    timelineToSettings() {
      this.$router.go(-1);
    },
    incrementWalkthrough(val) {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, val);
    },
    cancelWalkthrough() {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
    },
    triggerChooseList(newValue) {
      if (newValue === this.categoryChoose) return;
      this.loading = true;

      const timeline = {
        start_date: (new Date()).toISOString(),
        ...projectTimelines.default[newValue],
      };
      this.timeline = timeline;
      this.$store.dispatch(PROJECT_PUT, {
        projectId: this.$route.params.project_id,
        project: { timeline },
      })
        .then(() => { this.loading = false; });
    },
    itemSorter(item) {
      const start = moment((new Date(item.date)).toDateString());
      const current = moment((new Date()).toDateString());
      const end = moment(start).add(item.duration, 'day');
      if (current.diff(end) >= 0) {
        return 'DONE';
      }
      if (current.diff(start) >= 0 && end.diff(current) >= 0) {
        return 'IN_PROGRESS';
      }
      return 'QUEUED';
    },
    clickItem(item) {
      this.$router.push({
        name: 'ProjectTimelineEditItem',
        params: {
          project_id: this.$route.params.project_id,
          item_id: item.id,
        },
      });
    },
    clickEdit() {
      this.$router.push({
        name: 'ProjectTimelineEditTimeline',
        params: {
          project_id: this.$route.params.project_id,
        },
      });
    },
    backBtn() {
      this.$router.push({
        name: 'Project',
        params: {
          project_id: this.$route.params.project_id,
          tab: 'Declutter',
        },
      });
    },
  },
};
</script>
