<template>
  <div
    class="flex flex-col items-stretch w-10 h-12"
    :class="[
      {'w-10 h-12': size === 'small'},
      {'w-16 h-20': size === 'large'}
    ]"
  >
    <p
      class="font-bold text-white rounded-t-sm bg-primary dark:text-backfill "
      :class="[
        {'h-2/5 text-xs': size === 'small'},
        {'1/5 text-sm': size === 'large'}
      ]"
    >
      {{month}}
    </p>
    <div
      class="flex flex-col justify-center text-white bg-gray-500 rounded-b-lg h-3/5"
      :class="[
        {'h-3/5 text-xl': size === 'small'},
        {'5/5 text-4xl': size === 'large'}
      ]"
    >
      <p>{{day}}</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ProjectTimelineDate',
  props: {
    date: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      defalut: 'small',
      validate: (val) => ['small', 'large'].includes(val),
    },
  },
  computed: {
    month() {
      return moment(this.date).format('MMM');
    },
    day() {
      return moment(this.date).format('D');
    },
  },
};
</script>
