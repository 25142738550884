var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-stretch w-10 h-12",class:[
    {'w-10 h-12': _vm.size === 'small'},
    {'w-16 h-20': _vm.size === 'large'}
  ]},[_c('p',{staticClass:"font-bold text-white rounded-t-sm bg-primary dark:text-backfill ",class:[
      {'h-2/5 text-xs': _vm.size === 'small'},
      {'1/5 text-sm': _vm.size === 'large'}
    ]},[_vm._v(" "+_vm._s(_vm.month)+" ")]),_c('div',{staticClass:"flex flex-col justify-center text-white bg-gray-500 rounded-b-lg h-3/5",class:[
      {'h-3/5 text-xl': _vm.size === 'small'},
      {'5/5 text-4xl': _vm.size === 'large'}
    ]},[_c('p',[_vm._v(_vm._s(_vm.day))])])])}
var staticRenderFns = []

export { render, staticRenderFns }